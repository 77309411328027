import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: string;
}

export const IconArrowDown7 = ({ color = "#4FACE9", className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-arrow-down-7 ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_80_178)">
        <path
          className="path"
          d="M8.78145 8.5L5.48145 5.2L6.42411 4.25733L10.6668 8.5L6.42411 12.7427L5.48145 11.8L8.78145 8.5Z"
          fill={color}
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_80_178">
          <rect className="rect" fill="white" height="16" transform="matrix(0 -1 1 0 0 16.5)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconArrowDown7.propTypes = {
  color: PropTypes.string
};
