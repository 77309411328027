import React from "react";
import "./style.css";

import ImageMagnifying from "../static/img/magnifyingglass.svg";
import ImageVector1 from "../static/img/vector-1.svg";
import ImageMappin from "../static/img/mappin.svg";
import ImageCrosshair from "../static/img/crosshair.svg";
import Image1 from "../static/img/image.png";
import ImageVector from "../static/img/vector.svg";

export const Header = (): JSX.Element => {
  return (
    <div className="header-wrapper">
      <div className="frame-24">
        <div className="text-wrapper-8">Hi, User Name</div>

        <div className="text-wrapper-9">Welcome Back</div>
      </div>

      <div className="frame-25">
        <div className="frame-26">
          <img className="img-2" alt="Magnifying glass" src={ImageMagnifying} />

          <div className="text-wrapper-10">Find patient</div>
        </div>

        <div className="frame-27">
          <img className="vector" alt="Vector" src={ImageVector1} />

          <div className="frame-28">
            <div className="frame-29">
              <img className="img-2" alt="Map pin" src={ImageMappin} />

              <div className="text-wrapper-10">Location</div>
            </div>

            <img className="img-2" alt="Crosshair" src={ImageCrosshair} />
          </div>

          <div className="item-link">
            <div className="text-wrapper-11">Search</div>
          </div>
        </div>
      </div>

      <div className="bell">
        <div className="ellipse-wrapper" style={{ backgroundImage: `url(${ImageVector})` }}>
          <div className="ellipse-2" />
        </div>
      </div>

      <img className="image" alt="Image" src={Image1} />

      <div className="frame-30">
        <div className="text-wrapper-12">User Name</div>
      </div>
    </div>
  );
};
