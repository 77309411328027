import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Menu } from "../Menu";
import { Header } from "../Header";

import "./style.css";

interface SimpleTestPageProps {
  title?: string;
}

function SimpleTestPage({ title }: SimpleTestPageProps) {
  const [message, setMessage] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = async () => {
    try {
      const response = await fetch("/api/");
      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
      } else {
        setMessage("Error: " + response.status);
      }
    } catch (error) {
      setMessage("Error: " + error);
    }
  };

  return (
    <div className="dashboard-after">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <Header />
            <Menu />
            <div className="content-wrapper">
              <h1>{title}</h1>
              <p>Server status: {message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimpleTestPage;
