import React from "react";

interface Props {
  className: string;
}

export const IconArrowDown1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`icon-arrow-down-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_80_68)">
        <path
          className="path"
          d="M7.21868 7.99999L10.5187 4.69999L9.57601 3.75733L5.33334 7.99999L9.57601 12.2427L10.5187 11.3L7.21868 7.99999Z"
          fill="black"
        />
      </g>

      <defs className="defs">
        <clipPath className="clip-path" id="clip0_80_68">
          <rect className="rect" fill="white" height="16" transform="matrix(0 -1 -1 0 16 16)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
