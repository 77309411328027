import React from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";

export const CalendarView = (): JSX.Element => {
  return (
    <div className="dashboard-after">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <Header />
            <Menu />
            <div className="content-wrapper">
              <h1>Calendar</h1>
              <Calendar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
