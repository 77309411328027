import React, { useState } from "react";

import Avatar from "../static/img/avatar-big.png";
import Edit from "../static/img/profile-edit.svg";
import Logo from "../static/img/georgia-tech-logo-1.png";

import "./style.css";

import { Menu } from "../Menu";
import { Header } from "../Header";

export const UserProfileDetails = (): JSX.Element => {
  const [role, setRole] = useState("General Medical Doctor");
  const [organization, setOrganization] = useState("NYC Health Manhattan - Columbus Circle");
  const [location, setLocation] = useState("330 W 58th St #301, New York, NY 10019");
  const [firstName, setFirstName] = useState("George");
  const [lastName, setLastName] = useState("Burdell");
  const [phone, setPhone] = useState("111-777-7777");
  const [email, setEmail] = useState("G.Burdell@gatech.edu");

  return (
    <div className="dashboard-after">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <Header />
            <Menu />
            <div className="content-wrapper user-profile">

              <div className="component-parent">
                <div className="rectangle-wrapper">
                  <div className="component-child" />
                </div>
                <div className="component-2">
                  <div className="role">Role</div>
                  <input
                    type="text"
                    className="component-2-child text-input"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </div>
                <div className="component-1">
                  <div className="organization">Organization</div>
                  <input
                    type="text"
                    className="component-2-child text-input"
                    value={organization}
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </div>
                <div className="organization-location-parent">
                  <div className="organization-location">Organization Location</div>
                  <input
                    type="text"
                    className="component-2-child text-input"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <div className="profile">
                  <div className="profile1">Profile</div>
                </div>
                <div className="frame-child" />
                <div className="rectangle-parent">
                  <div className="component-inner" />
                  <div className="save">Save</div>
                </div>
                <div className="rectangle-group">
                  <div className="rectangle-div" />
                  <div className="cancel">Cancel</div>
                </div>
                <img className="image-icon" alt="" src={Avatar} />
                <div className="first-name-parent">
                  <div className="first-name">First name</div>
                  <input
                    type="text"
                    className="component-child1 text-input"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="group-wrapper">
                  <div className="burdell-parent">
                    <div className="last-name">Last name</div>
                    <input
                      type="text"
                      className="group-child text-input"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="component-wrapper">
                  <div className="phone-number-text-content-parent">
                    <div className="last-name">Phone Number</div>
                    <input
                      type="text"
                      className="group-child text-input"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <img className="edit-icon" alt="" src={Edit} />
                <div className="email-text-content-parent">
                  <div className="last-name">Email</div>
                  <input
                    type="text"
                    className="group-child text-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="georgia-tech-logo" alt="Georgia tech logo" src={Logo} />
    </div>
  );
};
