import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

import { ReactComponent as ImageCircles } from "../static/img/menu/circlesfour.svg";
import { ReactComponent as ImageCalendar } from "../static/img/menu/calendarblank.svg";
import { ReactComponent as ImageUser } from "../static/img/menu/usercircle.svg";
import { ReactComponent as ImagePie } from "../static/img/menu/pie-chart.svg";
import { ReactComponent as ImageQuestion } from "../static/img/menu/question.svg";
import { ReactComponent as ImageSignout } from "../static/img/menu/signout.svg";

export const Menu = (): JSX.Element => {
  const location = useLocation();
  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  const getLinkClass = (path: string) => {
    if (location.pathname === path || hoveredLink === path) return "menu-link menu-link-active";
    return "menu-link";
  };

  return (
    <>
      <div className="menu-sidebar" />
      <div className="menu-wrapper">
        <Link
          to="/"
          className={getLinkClass("/")}
          onMouseEnter={() => setHoveredLink("/")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageCircles className="menu-img" />
            <div className="menu-link-text ">Dashboard</div>
          </div>
        </Link>

        <Link
          to="/calendar"
          className={getLinkClass("/calendar")}
          onMouseEnter={() => setHoveredLink("/calendar")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageCalendar className="menu-img" />
            <div className="menu-link-text ">Calendar</div>
          </div>
        </Link>

        <Link
          to="/profile"
          className={getLinkClass("/profile")}
          onMouseEnter={() => setHoveredLink("/profile")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageUser className="menu-img" />
            <div className="menu-link-text ">Profile</div>
          </div>
        </Link>

        <Link
          to="/patient"
          className={getLinkClass("/patient")}
          onMouseEnter={() => setHoveredLink("/patient")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImagePie className="menu-img" />
            <div className="menu-link-text ">Patient Data</div>
          </div>
        </Link>

        <Link
          to="/help"
          className={getLinkClass("/help")}
          onMouseEnter={() => setHoveredLink("/help")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageQuestion className="menu-img" />
            <div className="menu-link-text ">Help</div>
          </div>
        </Link>

        <Link
          to="/login"
          className={getLinkClass("/login")}
          onMouseEnter={() => setHoveredLink("/login")}
          onMouseLeave={() => setHoveredLink(null)}
        >
          <div className="menu-link-wrapper">
            <ImageSignout className="menu-img" />
            <div className="menu-link-text ">Logout</div>
          </div>
        </Link>
      </div>
    </>
  );
};
