import React from "react";
import "./style.css";

import ImageArrowUp from "../static/img/iconamoon-arrow-up-2.svg";
import ImageBrain from "../static/img/brain-study-vector-illustration-man-woman-doctor-character-use-s.png";
import ImageVector22 from "../static/img/vector-2-2.svg";
import ImageVector24 from "../static/img/vector-2-4.svg";
import ImageLogo from "../static/img/georgia-tech-logo-1.png";
import { IconArrowDown1 } from "../icons/IconArrowDown1";
import { IconArrowDown7 } from "../icons/IconArrowDown7";

import { Menu } from "../Menu";
import { Header } from "../Header";
import DashboardPatientList from "./DashboardPatientList";

export const DashboardAfter = (): JSX.Element => {
  return (
    <div className="dashboard-after">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <div className="rectangle" />
            <div className="ellipse" />

            <Header />
            <Menu />
            <DashboardPatientList />

            <div className="frame">
              <div className="div">
                <div className="text-wrapper">Interactive Analytics Panel</div>

                <div className="text-wrapper-2">Display key patient analytics</div>
              </div>
            </div>

            <div className="rectangle-2" />

            <div className="frame-wrapper">
              <div className="frame-2">
                <div className="frame-3">
                  <div className="month">Oct 2024</div>

                  <div className="frame-4">
                    <div className="frame-5">
                      <IconArrowDown1 className="icon-arrow-down" />
                      <IconArrowDown7 className="icon-arrow-down" color="black" />
                    </div>

                    <img className="img" alt="Iconamoon arrow up" src={ImageArrowUp} />
                  </div>
                </div>

                <div className="frame-6">
                  <div className="frame-7">
                    <div className="text-wrapper-3">Mon</div>

                    <div className="text-wrapper-3">Tue</div>

                    <div className="text-wrapper-3">Wed</div>

                    <div className="text-wrapper-3">Thu</div>

                    <div className="text-wrapper-3">Fri</div>

                    <div className="text-wrapper-3">Sat</div>

                    <div className="text-wrapper-3">Sun</div>
                  </div>

                  <div className="div-wrapper">
                    <div className="frame-8">
                      <div className="frame-7">
                        <div className="mon-wrapper">
                          <div className="mon">1</div>
                        </div>

                        <div className="mon-wrapper">
                          <div className="mon">1</div>
                        </div>

                        <div className="mon-wrapper">
                          <div className="mon">1</div>
                        </div>

                        <div className="mon-wrapper">
                          <div className="mon">1</div>
                        </div>

                        <div className="mon-wrapper">
                          <div className="mon">1</div>
                        </div>

                        <div className="frame-9">
                          <div className="mon-2">1</div>
                        </div>

                        <div className="frame-9">
                          <div className="mon-2">2</div>
                        </div>
                      </div>

                      <div className="frame-7">
                        <div className="frame-10">
                          <div className="mon-2">3</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">4</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">5</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">6</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">7</div>
                        </div>

                        <div className="frame-11">
                          <div className="mon-2">8</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">9</div>
                        </div>
                      </div>

                      <div className="frame-7">
                        <div className="frame-10">
                          <div className="mon-2">10</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">11</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">12</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">13</div>
                        </div>

                        <div className="frame-12">
                          <div className="mon-3">14</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">15</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">16</div>
                        </div>
                      </div>

                      <div className="frame-7">
                        <div className="frame-10">
                          <div className="mon-2">17</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">18</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">19</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">20</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">21</div>
                        </div>

                        <div className="frame-12">
                          <div className="mon-5">22</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">23</div>
                        </div>
                      </div>

                      <div className="frame-7">
                        <div className="frame-10">
                          <div className="mon-4">24</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">25</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">26</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-2">27</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">28</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">29</div>
                        </div>

                        <div className="frame-10">
                          <div className="mon-4">30</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="frame-13">
              <div className="frame-14">
                <div className="frame-15">
                  <div className="frame-16">
                    <div className="frame-17">
                      <div className="text-wrapper-4">Mon</div>

                      <div className="text-wrapper-5">14</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="tue">Patient 2</div>

                    <div className="tue-2">10:00am - 10:30am</div>
                  </div>
                </div>

                <IconArrowDown7 className="icon-arrow-down" color="black" />
              </div>
            </div>

            <div className="frame-19">
              <div className="frame-14">
                <div className="frame-15">
                  <div className="frame-20">
                    <div className="frame-17">
                      <div className="text-wrapper-4">Tues</div>

                      <div className="text-wrapper-5">15</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="tue">Patient 3</div>

                    <div className="tue-2">10:00am - 10:30am</div>
                  </div>
                </div>

                <IconArrowDown7 className="icon-arrow-down" color="black" />
              </div>
            </div>

            <div className="text-wrapper-6">Upcoming Patients</div>

            <div className="frame-21">
              <div className="heading-enrolled">View All</div>

              <IconArrowDown7 className="icon-arrow-down" color="#4CB269" />
            </div>

            <div className="frame-22">
              <div className="text-wrapper-7">Patient Portal</div>

              <div className="frame-23">
                <div className="heading-enrolled">View All</div>

                <IconArrowDown7 className="icon-arrow-down" color="#4FACE9" />
              </div>
            </div>

            <div className="frame-31">
              <div className="ellipse-3" />

              <div className="ellipse-4" />

              <div className="ellipse-5" />
            </div>

            <img className="brain-study-vector" alt="Brain study vector" src={ImageBrain} />
          </div>

          <div className="overlap-3">
            <div className="frame-45">
              <div className="frame-46">
                <div className="frame-47">
                  <div className="frame-47">
                    <div className="frame-48">
                      <div className="text-wrapper-18">Deep Vein Thrombosis</div>
                    </div>

                    <div className="frame-49">
                      <div className="text-wrapper-19">High Cholesterol or Hypertension</div>
                    </div>
                  </div>
                </div>

                <img className="vector-5" alt="Vector" src={ImageVector22} />

                <div className="frame-50">
                  <div className="text-wrapper-20">Calculate</div>
                </div>
              </div>
            </div>

            <div className="frame-51">
              <div className="frame-46">
                <div className="frame-47">
                  <div className="frame-47">
                    <div className="frame-48">
                      <div className="text-wrapper-18">Chronic Kidney Disease Risk</div>
                    </div>

                    <div className="frame-49">
                      <div className="text-wrapper-19">Probability of Having Stage 3-5 CKD</div>
                    </div>
                  </div>
                </div>

                <img className="vector-5" alt="Vector" src={ImageVector22} />

                <div className="frame-50">
                  <div className="text-wrapper-20">Calculate</div>
                </div>
              </div>
            </div>

            <div className="frame-52">
              <div className="text-wrapper-7">Health Assessments</div>

              <div className="frame-23">
                <div className="heading-enrolled">View All</div>

                <IconArrowDown7 className="icon-arrow-down" color="#4FACE9" />
              </div>
            </div>

            <div className="overlap-4">

              <div className="frame-53">
                <div className="frame-46">
                  <div className="frame-47">
                    <div className="frame-47">
                      <div className="frame-48">
                        <div className="text-wrapper-18">Colon Cancer Risk</div>
                      </div>

                      <div className="frame-49">
                        <div className="text-wrapper-19">Genetic Risk, Family History, and Personal Factors</div>
                      </div>
                    </div>
                  </div>

                  <img className="vector-5" alt="Vector" src={ImageVector24} />

                  <div className="frame-50">
                    <div className="text-wrapper-20">Calculate</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img className="georgia-tech-logo" alt="Georgia tech logo" src={ImageLogo} />
        </div>
      </div>
    </div>
  );
};
