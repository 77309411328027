import React from "react";

interface Props {
  className: string;
}

export const HeartCardiogram1 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`heart-cardiogram-1 ${className}`}
      fill="none"
      height="84"
      viewBox="0 0 84 84"
      width="84"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.7461 32.9036C15.7461 23.0085 22.3536 13.8329 31.4015 13.8329C37.6803 13.8329 42.3394 17.9496 45.2152 23.7897C48.0909 17.95 52.75 13.8329 59.0289 13.8329C68.0779 13.8329 74.6843 23.0101 74.6843 32.9036C74.6843 54.0826 45.2152 69.138 45.2152 69.138C45.2152 69.138 25.3647 59.6103 18.2477 43.9818H35.8281L38.6225 39.0292L41.8864 50.4193L49.53 40.6213H59.083V37.1647H47.8343L43.3436 42.9213L39.6736 30.1154L33.8006 40.5252H16.9246C17.2921 41.708 17.7372 42.8606 18.2477 43.9818L10.5457 43.9816V40.525L16.9246 40.5252C16.1733 38.1072 15.7461 35.5628 15.7461 32.9036Z"
        fill="#333333"
      />
    </svg>
  );
};
