import React, { useState }  from "react";
import { Link, useNavigate } from "react-router-dom";

import BG from "../static/img/signup-bg.png";
import Logo from "../static/img/georgia-tech-logo-1.png";
import Cardio from "../static/img/signup-cardio.svg";
import Fhir from "../static/img/logo-fhir-island.png";

import "./style.css";

export const SignUp = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    navigate("/");
  }

  return (
    <div className="new-user-sign-up">
      <img className="bg-icon" alt="" src={BG} />
      <input type="text" className="new-user-sign-up-child text-input" />
      <input type={showPassword ? 'text' : 'password'} className="new-user-sign-up-item text-input" />
      <input type="text" className="new-user-sign-up-inner text-input" />
      <input type="text" className="rectangle-div text-input" />
      <input type="text" className="new-user-sign-up-child1 text-input" />
      <div className="username">Username:</div>
      <div className="email">Email:</div>
      <div className="first-name">First Name:</div>
      <div className="last-name">Last Name:</div>
      <b className="create-an-account">Create an account</b>
      <div className="rectangle-parent">
          <input type="checkbox" className="show-password-checkbox" onChange={() => setShowPassword(!showPassword)} />
          <div className="show-password">Show password</div>
      </div>
      <div className="rectangle-group">
          <button className="sign-up" onClick={handleLogin}>
            Sign Up
          </button>
      </div>
      <div className="password">Password:</div>
      <img className="georgia-tech-logo-1" alt="" src={Logo} />
      <div className="have-an-account-login">
        <div className="show-password">
          <span className="already-have-an">Already have an account?</span>
          <Link to="/login" className="log-in">
            Log in
          </Link>
        </div>
      </div>
      <div className="heart-rate-icon-solid">
          <div className="heart-rate-icon-solid-child" />
          <img className="heart-cardiogram-icon" alt="" src={Cardio} />
      </div>
      <div className="your-all-in-one-risk-container">
          <p className="your-all-in-one-risk">Your All-in-One Risk Calculator Hub for</p>
          <p className="your-all-in-one-risk">Smarter, Faster Medical Decisions</p>
      </div>
      <img className="logo-fhir-island-icon" alt="" src={Fhir} />
    </div>
  );
}
