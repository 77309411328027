import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import UserAvatar from "../static/img/user-avatar.png";

interface DashboardPatientProps {
  id: number;
}

function DashboardPatient({ id }: DashboardPatientProps) {
  const [patientData, setPatientData] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (patientData === null && !loading) {
      fetchPatient();
    }
  }, []);

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/patients/${id}`);
      if (response.ok) {
        const data = await response.json();
        if (data.success === true) {
          setPatientData(data.data);
        } else {
          throw new Error(data.error);
        }
      } else {
        throw new Error(response.statusText);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(error instanceof Error ? error.message : String(error));
    }
    setLoading(false);
  };

  return (
    <Link to={"/patient/" + id} className={loading ? "patient-list-item patient-list-item-loading" : "patient-list-item"}>
      {error && <div>Error: {errorMessage}</div>}
      {patientData && (
        <>
          <div className="patient-list-item-wrapper">
            <img className="patient-list-avatar" alt="User Avatar" src={UserAvatar} />
            <div className="patient-list-info">
              <div className="patient-list-name">{patientData.givenName} {patientData.familyName}</div>
              <div className="patient-list-location">{patientData.city}, {patientData.state}</div>
            </div>
          </div>
          <div className="patient-list-id">Patient ID: {id}</div>
        </>
      )}
    </Link>
  );
}

export default DashboardPatient;
