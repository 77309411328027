import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Menu } from "../Menu";
import { Header } from "../Header";

import "./style.css";

function SimplePatientPage() {
  const { patientId } = useParams();
  const prevPatientId = useRef<string | null>(null);

  const [patientData, setPatientData] = useState<any>(null);
  const [riskData, setRiskData] = useState<any>(null);
  const [aiData, setAiData] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [loadingAi, setLoadingAi] = useState<boolean>(false);
  const [errorMessageAi, setErrorMessageAi] = useState<string>("");
  const [errorAi, setErrorAi] = useState<boolean>(false);

  useEffect(() => {
    if (patientId && prevPatientId.current !== patientId) {
      prevPatientId.current = patientId;
      fetchData();
    }
  }, [patientId]);

  useEffect(() => {
    if (riskData !== null) {
      setLoadingAi(true);
      fetchAiData();
    }
  }, [riskData]);

  const fetchData = async () => {
    try {
      const responsePatient = await fetch(`/api/patients/${patientId}`);
      if (responsePatient.ok) {
        const dataPatient = await responsePatient.json();
        if (dataPatient.success === true) {
          setPatientData(dataPatient.data);
        } else {
          throw new Error(dataPatient.error);
        }
      } else {
        throw new Error(responsePatient.statusText);
      }

      const responseRisk = await fetch(`/api/calculate_risks/${patientId}`);
      if (responseRisk.ok) {
        const dataRisk = await responseRisk.json();
        if (dataRisk.success === true) {
          setRiskData(dataRisk.risks);
        } else {
          throw new Error(dataRisk.error);
        }
      } else {
        throw new Error(responseRisk.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setError(true);
    }
    setLoading(false);
  };

  const fetchAiData = async () => {
    try {
      const responseAi = await fetch(`/api/ask_ai/${patientId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(riskData)
      });
      if (responseAi.ok) {
        const dataAi = await responseAi.json();
        if (dataAi.success === true) {
          setAiData(dataAi.advice);
        } else {
          throw new Error(dataAi.error);
        }
      } else {
        throw new Error(responseAi.statusText);
      }
    } catch (error) {
      setErrorMessageAi(error instanceof Error ? error.message : String(error));
      setErrorAi(true);
      console.error(error);
    }
    setLoadingAi(false);
  };

  return (
    <div className="dashboard-after">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="overlap-group">
            <Header />
            <Menu />
            <div className="content-wrapper patient-page">
              <h1>Patient: {patientId}</h1>
              {loading && <p>Loading...</p>}
              {error && <div className="error-message">{errorMessage}</div>}
              {!loading && !error && (
                <>
                  <div>
                    <h2>Patient:</h2>
                    {Object.entries(patientData).map(([name, value]) => (
                      <p key={name}>{name}: {String(value)}</p>
                    ))}
                  </div>

                  <div>
                    <h2>Risks:</h2>
                    {Object.entries(riskData).map(([name, value]) => (
                      <p key={name}>{name}: {String(value)}</p>
                    ))}
                  </div>
                </>
              )}
              {loadingAi && <h2>Loading AI advice...</h2>}
              {errorAi && <div className="error-message">AI error: {errorMessageAi}</div>}
              {!loadingAi && !errorAi && aiData && (
                <div>
                  <h2>AI advice:</h2>
                  <p>{aiData}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimplePatientPage;
