import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SimpleTestPage from "./SimpleTestPage/SimpleTestPage";
import { LoginPage } from "./LoginPage";
import { DashboardAfter } from "./DashboardAfter";
import { CalendarView } from "./CalendarView";
import { UserProfileDetails } from "./UserProfileDetails";
import { SignUp } from "./SignUp";
import SimplePatientPage from "./SimplePatientPage/SimplePatientPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DashboardAfter />} />
        <Route path="patient" element={<SimpleTestPage title="Patient" />} />
        <Route path="profile" element={<UserProfileDetails />} />
        <Route path="calendar" element={<CalendarView />} />
        <Route path="help" element={<SimpleTestPage title="Help" />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="/patient/:patientId" element={<SimplePatientPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
